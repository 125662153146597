body,
html {
  height: 100%;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  color: #3a3a3a;
}

main,
.main-box {
  width: 100%;
}

footer p {
  padding: 2.2rem 0px;
  margin: 0 5%;
  color: #909090;
  font-size: 16px;
}

.main-container {
  display: flex;
  min-height: calc(100vh - 50px);
}

.content {
  flex: 1;
  padding: 20px;
}

.main-box {
  background-color: #f8f8f8;
  transition: 0.5s;
}

.button-none {
  border: 0px;
  background-color: #ffffff00;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.label-input {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.btn {
  border-radius: 50px;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  outline: initial;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #48494b;
}

.btn-big {
  padding: 10px 40px !important;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}

.btn-primary {
  background-color: #48494b;
  border-color: #48494b;
  color: #fff;
  font-family: "Nunito", sans-serif;
}

.btn-primary:hover {
  background-color: #48494b;
  border-color: #48494b;
  color: #fff;
}

.btn-tabela {
  border-radius: 0.1rem;
  background-color: initial;
  border-color: transparent;
  color: #131113;
}

.btn-tabela:hover {
  color: #131113;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-user {
  width: 100%;
  text-align: center;
}

.card,
.card-home {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.75rem;
  border: initial;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
}

.card-home {
  padding: 1rem;
  height: fit-content;

  a {
    color: black;
    text-decoration: none;
    font-size: 24px;
  }

  i {
    font-size: 24px;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}

.card .card-body {
  padding: 1.75rem;
}

.p-datatable {
  font-size: 13px;

  .p-datatable-tbody > tr > td {
    padding: 0.1rem 0.5em;
  }

  .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5em;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last
{
  height: 1.5em;
}

.bread-crumb ol {
  padding: 0em;
  margin-bottom: 0em;
}

.bread-crumb h1 {
  margin: 0;
  margin-right: 0.5em;
  padding-bottom: 0em;
  margin-bottom: 0em;
}

.bread-crumb-box hr {
  margin-bottom: 0.8em;
}

.p-button, .btn {  
  font-size: 12px;
}

.p-dropdown .p-dropdown-label {
  font-size: 13px;
}

.p-inputtext {
  font-size: 12px;
}

.p-dropdown-item, .p-autocomplete-item {
  font-size: 12px;
}

.p-datepicker .p-inputtext {
  font-size: 12px;
}

.p-datepicker {
  font-size: 12px;
  width: auto;
}

.p-datepicker .p-inputtext {
  font-size: 12px;
}

.p-datepicker .p-datepicker-header {
  font-size: 12px;
  padding: 5px;
}

.p-datepicker-calendar-container .p-datepicker-calendar {
  font-size: 12px;
  padding: 5px;
}

.p-datepicker-month,
.p-datepicker-year {
  font-size: 12px;
  padding: 0 5px;
}

.p-autocomplete-dropdown{
  background: transparent;
  border: inherit;
  color: black;
  position: absolute;
  right: 0;
  top: -1px;
}

.field-checkbox{
  font-size: 13px;
}

.version-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 0.8em;
  color: #777;
}

.p-inputtext, .p-calendar .p-inputtext {
  padding: 5.8px 9.6px
} 

.logim-form .form-group .label-input {
  font-size: 11px;
  background: transparent;
}

.p-sidebar-content{
  font-size: 12px;
}

.p-input-icon-left .p-inputtext {
  padding: 5.8px 30.6px
}

.logim-form .label-input {
  top: -15px;
  left: -2px;
}

.card-filial .p-card-title {
  margin-bottom: 0.3rem;
}

.card-filial p {
  margin-bottom: 0.4rem;
}

.card-filial .p-card .p-card-content {
  padding: 0.5em 0em
}

.custom-tooltip {
  font-size: 0.75rem;
  padding: 4px;
}

.modal-padrao{
  font-size: 12px;
}

.p-none {
  padding: 0em;
}

.p-row-odd {
  background-color: #e5e3e3!important;
}

.p-datatable-odd {
  background-color: #ffffff;
}

.p-multiselect-label, .p-multiselect-item span {
  font-size: 12px;
}

.p-checkbox-box {
  width: 20px;
  height: 20px;
}

.small-table .p-datatable {
  font-size: 0.8rem;
}

.small-table .p-datatable-thead > tr > th,
.small-table .p-datatable-tbody > tr > td {
  padding: 0.1rem 0.3rem
}

.small-table .p-datatable-tbody > tr {
  height: 30px;
}
